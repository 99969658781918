import React, { Component } from 'react'

class Input extends Component<{}> {
  constructor(props){
    super(props)
    this.state = {
      value: this.props.value || ''
    }
    this.input = React.createRef();
    this.underline = React.createRef();
    this.label = React.createRef();
  }
  selected = () =>{
    if(this.props.onSelected) this.props.onSelected()
    this.underline.current.style.width = '100%'
    this.label.current.style.transform = 'translate(0, -20px) scale(.75)'
  }
  deselected = () => {
    if(this.props.onDeselected) this.props.onDeselected()
    if((!this.props.stateless && (this.props.value === '' || this.props.value === undefined || this.props.value === null)) ||
    (this.props.stateless && (this.state.value === '' || this.state.value === undefined || this.state.value === null))){
      this.underline.current.style.width = 0
      this.label.current.style.transform = 'translate(0, 0px)'
    }

    if(this.state.value === '' || this.state.value === undefined || this.state.value === null){
      this.underline.current.style.width = 0
      this.label.current.style.transform = 'translate(0, 0px)'
    }
  }
  handleChange = () => {
    let value = this.input.current.value
    this.setState({value})
    if(this.props.onChange) this.props.onChange({value, prop: this.props.name})
  }
  componentWillReceiveProps(nuProps){
    if(this.props.value != nuProps.value) this.setState({value: nuProps.value})
    if((this.props.value !== '' || this.props.value !== null ) && this.props.value !== nuProps.value){
      this.selected()
    }
  }
  componentDidMount(){
    if(this.props.name == 'rss_interval'){
      console.log({rss_interval: this.state})
    }
    if((this.props.value !== '' && this.props.value !== null )){
      this.selected()
      this.deselected()
    }
  }
  render() {
    return (
      <div className="input-outer-wrap" style={{marginBottom: this.props.marginBottom, width: this.props.width || 'auto', marginRight: this.props.style ? this.props.style.marginRight : null}}>
        <label 
          ref={this.label}
          style={{
            left: this.props.icon ? 27 : 0,
            color: this.props.color || '#333',
            fontSize: this.props.style && this.props.style.fontSize ? this.props.style.fontSize : 18
          }}
          className="input-label">
            {this.props.placeholder}
        </label>
        {
          this.props.icon && <i style={{
            position: 'absolute',
            top: this.props.iconOffsetTop || 4,
            left: this.props.iconOffsetLeft || 0,
            color: this.props.value !== '' 
                    && this.props.value !== null
                    && this.props.value !== undefined
                    && this.props.completedColor ?
                      this.props.completedColor
                    : 
                      (this.props.color || '#333'),
              fontSize: 18
            }}
            className={'input-icon '+this.props.icon}></i>
        }
        <input
          ref={this.input}
          id={this.props.id}
          disabled={this.props.disabled}
          className="input-element"
          type={ this.props.type ? this.props.type : 'text'}
          value={ this.props.stateless ? this.state.value : this.props.value}
          onSelect={this.selected}
          onBlur={this.deselected}
          onChange={this.handleChange}
          name={this.props.name}
          style={{
            color: this.props.color || '#333',
            paddingLeft: this.props.icon ? 27 : 0,
            caretColor: this.props.color || '#333',
            width: '100%',
            fontSize: this.props.style && this.props.style.fontSize ? this.props.style.fontSize : 18
          }}
        />
        <div style={{
          background: this.props.color || '#333'
        }} ref={this.underline} className="input-element-underline"></div>
        <div style={{
          background: this.props.color || '#333'
        }} className="input-element-underline-bg"></div>
      </div>
    )
  }
}

export default Input