import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../redux/actions'
import './css/list-manager.scss'
import TextInput from './TextInput'
export const ListManager = (props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentItems, setCurrentItems] = useState(false)
  const [totalItems, setTotalItems] = useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    if(currentItems){
      setItems([...totalItems])
    } else {
      searchItems({value: searchQuery})
      setItems([...props.items])
    }
  }, [props, itemsPerPage, currentPage])

  const setPageSize = async (value, autoPageSelect = true) => {
    if(autoPageSelect){
      // Get the first item currently viewable on the page
      const firstItem = currentItems[0]
      
      // Run some calculations to determine where the page
      // should be set to view the same posts after the page
      // size has changed
      let startIndex = 0, current_page
      
      // Get the index of the first item on the page
      totalItems.forEach((item, index)=>{
        if (JSON.stringify(firstItem) == JSON.stringify(item)) startIndex = index
      })
      
      // Turn it into a page number
      current_page = parseInt(startIndex / value)+1
      
      // if the total items is less than the page size
      // set the current page to 1
      if(totalItems.length <= value){
        current_page = 1
      }
      
      // Set the current page to the new page
      // with the currently viewed items showing
      setCurrentPage(current_page)
    }

    // Set the page size
    setItemsPerPage(value)
  }

  const setItems = (items) => {
    if(!items) items = props.items
    const start = (currentPage-1)*itemsPerPage
    // console.log({start, end: parseInt(start) + parseInt(itemsPerPage)})
    setTotalItems(items)
    const theThings = items.slice(start, start+itemsPerPage)
    if(theThings)
    // console.log({theThings: theThings.length})
    setCurrentItems(theThings)
  }
  const filterItems = (value, path) => {
    const results = props.items.filter( item => {
      path.split(".").forEach( p => {
        item = item[p]
      } )
      return item.toLowerCase().indexOf(value.toLowerCase()) > -1
    })
    return results
  }

  const filterSubItems = (value, path) => {
    const results = props.items.filter( item => {
      
      let result = false
      path.split(".$.").forEach((subpath, spIndex) => {
        subpath.split(".").forEach( p => {
          if(Array.isArray(item)){
            item.forEach(subItem=>{
              
              if(subItem[p].toLowerCase().indexOf(value.toLowerCase()) > -1){
                result = true
              }
            })
          } else {
            item = item[p] || item
          }
          
        } )
      })
      return result

    })
    return results
  }

  const searchItems = ({value}) => {
    setSearchQuery(value)
    if (value == "" || value == null) setItems(props.items)
    let final = []
    

    if("string" == typeof props.searchPath ){
      const results = filterItems(value, props.searchPath)
      final = final.concat(results).unique()
    } else if (Array.isArray(props.searchPath)){
      props.searchPath.forEach(path => {
        const results = filterItems(value, path)
        final = final.concat(results).unique()
      })
    }
   
    if("string" == typeof props.subItemSerchPath ){
      const results = filterSubItems(value, props.subItemSerchPath)
      final = final.concat(results).unique()
    } else if (Array.isArray(props.subItemSerchPath)){
      props.subItemSerchPath.forEach(path => {
        const results = filterSubItems(value, path)
        final = final.concat(results).unique()
      })
    }


    final = final.filter(n => n)
    setItems(final)
    setCurrentPage(1)
  }

  const GeneratePagination = (items) => {
    // itemsPerPage  currentItems
    let tabs = []
    for (let i = 0; i < ((totalItems.length+1)/itemsPerPage); i++) {
        tabs.push(<div
          className={"pagination-tab" + (currentPage-1 == i ? " active" : "")}
          onClick={()=>setCurrentPage(i+1)}>{i+1}</div>)
    }
    return tabs
  }
  return (
    <div className="list-manager">
      <div className="list-manager-inner">
        <p className="list-title">{props.title}</p>
        <div className="list-manager-header">
          <p className="filters-title">FILTERS</p>
          <div style={{display: 'flex', alignItems: 'center', height: "100%"}}>
            <TextInput width="100%" type="text" placeholder="Search" name="search" stateless value={searchQuery} onChange={ searchItems }/>
            <div className="select" id="items-per-page">
              <select value={itemsPerPage} onChange={({target})=>setPageSize(parseInt(target.value))}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
              </select>
              <div className="select__arrow"></div>
            </div>
            <p style={{margin: 0}}>items per page</p>
          </div>
        </div>
        <div className="list-manager-results">
            {
              currentItems.length > 0 && currentItems.map((item, index) => <props.renderer key={item._id || index} {...item}/> )
            }
        </div>
        <div className="list-manager-footer">
          <div className="pagination">
            {GeneratePagination(currentItems)}
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    state
  }
}
 
function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ListManager)