import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, NavLink as Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../redux/actions/index'

import HeaderNav from '../components/admin/HeaderNav'
import Dashboard from '../components/admin/Dashboard';
import Settings from '../components/admin/Settings';
import Releases from '../components/admin/Releases';
import EggHunt from '../components/admin/EggHunt';
import Bio from '../components/admin/Bio';
// import Gallery from './Admin/Gallery'
import RSS from './Admin/RSS'
import { server } from '../config'

const AdminController = (props) => {
  let showAdminPanel = props.redux.user.login && (props.redux.user.super_admin || props.redux.user.team || props.redux.user.inner_circle)
    if(showAdminPanel){
      return (
        <div style={{ height: 'calc(100% - 50px)'}} id="admin-panel">
        <Route path="/" component={HeaderNav} />
          {
            props.redux.user.super_admin && <>
            <Route exact path="/" component={Dashboard} />
            <Route path="/releases" component={Releases} />
            <Route path="/egg" component={EggHunt} />
            </>
          }
            <Route path="/rss" component={RSS} />
           
          {
            props.redux.user.super_admin && <>
            {/* <Route path="/admin/gallery" component={Gallery} /> */}
            <Route path="/bio" component={Bio} />
            <Route path="/settings" component={Settings} /></>
          }
        </div>
      )
    } else {
      return (
        <div id="admin-panel" style={{padding: 40, background: "#18191c", width: "100%", display: "flex",flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
          <img src={require('../images/logo-masked.png').default} style={{ maxWidth: 320, marginBottom: 60, position: "absolute", transform: "translate(0, -250%)"}}/>
          <a href={server+"/auth/discord/signin"}><img src={require('./../images/social/discord-animated.gif').default} style={{maxWidth: 150, cursor: "pointer", borderRadius: 140, boxShadow: "0px 0px 15px rgba(0,0,0,0.5)"}} /></a>
        </div>
      )
    }
}


function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminController)