import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css';
import './css/animations.css';

import { Provider } from 'react-redux'
import Socket from './components/Socket'
import AudioPlayer from './components/AudioPlayer';
import './css/frameworks/bootstrap.grid.css'
import AdminController from './containers/AdminController';

// Redux Reducers
import store from './redux/store'

import "./css/admin.scss"

class App extends Component {
  constructor(props){
    super(props)
  
    this.state = {}
  }
  render() {
    return (
      <Provider store={store}>
        <div style={{height: '100%'}}>
          <Socket />
          <Router>
            <Switch>
              <AdminController />
            </Switch>
          </Router>
          <AudioPlayer />
        </div>
      </Provider>
    );
  }
}

export default App;
