import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../redux/actions/index'
import TextInput from '../../components/UI/TextInput'
import AllReposters from '../../components/admin/rss/AllReposters'
import Footer from '../../components/Footer'

let feedInfoDebounce;
  
export const RSS = (props) => {
  const [channelID, setChannelID] = useState("")
  const [rssInterval, setRssInterval] = useState("")
  const [reposterActive, setReposterActive] = useState(true)
  const [title, setTitle] = useState("")
  const [feeds, setFeeds] = useState([])
  const [rssInfo, setRssInfo] = useState(false)
  const [newRSSURL, setNewRSSURL] = useState("")
  const [newRSSTitle, setNewRSSTitle] = useState("")
  const [newRSSActive, setNewRSSActive] = useState(true)
  const [newRSSpriority, setNewRSSPriority] = useState(5)
  const [newRSSPostLimit, setNewRSSPostLimit] = useState(5)  
  const [newRSSKeyword, setNewRSSkeyword] = useState("")
  const [newRSSKeywords, setNewRSSkeywords] = useState([])
  const [newRSSCategories, setNewRSSCategories] = useState([])
  const [newRssApprovalRequired, setNewRssApprovalRequired] = useState(true)
  const [isYTSearchQuery, setIsYTSearchQuery] = useState(false)
  const [runOnCreate, setRunOnCreate] = useState(false)
  const toggleAddNewReposter = () => {
    const reposterContainer = document.getElementById("add-reposter")
    const newReposterButton = document.getElementById("new-rpstr-btn")
    reposterContainer.classList.toggle("open");
    const isOpen = reposterContainer.classList.contains("open")
    newReposterButton.innerHTML = isOpen ? "CLOSE" : "NEW REPOSTER"
  }

  const addNewRSSKeyword = () => {
    if(!newRSSKeyword || newRSSKeyword.trim() == "") return
    const newState = [...newRSSKeywords, newRSSKeyword.trim()]
    setNewRSSkeyword(null)
    document.getElementsByName('keyword')[0].value = null
    setNewRSSkeywords(newState)
  }
  
  const handleCheckboxChange = (item, stateSetter, isYoutubeSearchEnabler) => {
    if(isYoutubeSearchEnabler && item.target.checked){
      setRssInfo(false)
    }
    stateSetter(item.target.checked)
  }
  
  const handleInputchange = (item, stateSetter, arrayToAddTo = false, isRSS = false) => {
    if(arrayToAddTo){
      const update = [...arrayToAddTo, item.value]
      return stateSetter(update)
    }

    if(isRSS){
      clearTimeout(feedInfoDebounce)
      let url = item.value
      if(url.indexOf('youtube') > -1){
        url = `https://www.youtube.com/feeds/videos.xml?channel_id=${url.split("channel/").pop()}`
      }
      stateSetter(url)
      feedInfoDebounce = setTimeout(()=>{
        fetch('/api/rss/info/', {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({url})
        }).then(r=>r.json()).then(info=>{
          if(info.error) return console.log(info.error)
          setRssInfo(info)
          if(newRSSTitle == "" && info.feed) setNewRSSTitle(info.feed.title)
        })
      }, 1000)
    } else {
    
      stateSetter(item.value)
    }
  }
  
  const addFeed = () => {
    clearTimeout(feedInfoDebounce)
    if(!newRSSURL || (!newRSSTitle && !isYTSearchQuery)) return alert("You must enter all feed fields to add a feed (keywords not required)")

    const newFeed = {
      title: isYTSearchQuery ? "YT Search: "+newRSSURL : newRSSTitle,
      active: newRSSActive,
      priority: newRSSpriority,
      keywords: newRSSKeywords,
      categories: newRSSCategories,
      request_approval: newRssApprovalRequired,
      is_youtube_search: isYTSearchQuery,
      repost_limit: newRSSPostLimit
    }
    console.log({newFeed})
    if(!isYTSearchQuery){
      newFeed.url = newRSSURL
    } else {
      newFeed.search_term = newRSSURL
    }
      

    setFeeds([...feeds, newFeed])
    
    setNewRSSURL("")
    setNewRSSTitle("")
    setNewRSSActive(true)
    setNewRSSPriority(5)
    setNewRSSkeyword("")
    setNewRSSkeywords([])
    setNewRSSCategories([])
    setNewRssApprovalRequired(true)
    setRssInfo(false)
    setNewRSSPostLimit(5)
    setIsYTSearchQuery(false)

    document.getElementsByName('feed_url')[0].value = ""
    if(!isYTSearchQuery) document.getElementsByName('feed_title')[0].value = ""
    // document.getElementsByName('feed_priority')[0].value = ""
  }
  
  const addCategory = (category) => {
    if(newRSSCategories.indexOf(category) != -1) return
    const newCategories = [...newRSSCategories, category]
    setNewRSSCategories(newCategories)
    
    const index = rssInfo.categories.indexOf(category);
    if (index > -1) {
      let updatedRSSInfo = Object.assign({}, rssInfo)
      updatedRSSInfo.categories.splice(index, 1);
      setRssInfo(updatedRSSInfo)
    }
  }

  const refreshRSSCategories = () => {
    fetch('/api/rss/info/'+encodeURIComponent(newRSSURL)).then(r=>r.json()).then(info=>{
      newRSSCategories.map(kwd => {
        const index = info.categories.indexOf(kwd);
        if (index > -1) {
          info.categories.splice(index, 1);
        }
      }) 
      setRssInfo(info)
    })
  }
  
  const deleteKeyword = (kwd) => {
    if(!window.confirm("Delete Keyword: "+kwd) ) return false
    const index = newRSSKeywords.indexOf(kwd);
    if (index > -1) {
      let updatedRSSKewords = [...newRSSKeywords]
      updatedRSSKewords.splice(index, 1);
      setNewRSSkeywords(updatedRSSKewords)
    }
  }
  
  const deleteCategory = (kwd) => {
    if(!window.confirm("Delete category: "+kwd) ) return false
    const index = newRSSCategories.indexOf(kwd);
    if (index > -1) {
      let updatedRSSCategories = [...newRSSCategories]
      updatedRSSCategories.splice(index, 1);
      setNewRSSCategories(updatedRSSCategories)
    }
  }

  const createReposter = () => {
    let proceed = true
    // Check if a if the reposter has all the required data
    if(feeds.length === 0 ) return alert("You must add at least one feed to your reposter to create it")
    if(!channelID) return alert("You must give your reposter a repost channel id to create it")
    if(!title) return alert("You must give your reposter a title to create it")
    
    // Check if a new rss feed was not added yet
    // If it does ask the user to confirm reposter creation with the feed
    if(newRSSTitle && newRSSTitle != "" && newRSSURL && newRSSURL != "" && newRSSCategories.length > 0 && newRSSKeywords.length > 0){
      proceed = window.confirm("It looks like you have some feed information entered but the feed has not been saved, would you like to continue?")
}
    if(!proceed) return

    props.addNewReposter({
      channel_id: channelID,
      active: reposterActive,
      run_on_create: runOnCreate,
      title,
      feeds
    }).then((result)=>{
      // Make sure it is successfull and then clear the form
      if(result.action.type !== "ADD_REPOSTER_FULFILLED") return false
      setChannelID("")
      setReposterActive(true)
      setTitle("")
      setFeeds([])
      setRssInfo(false)
      setNewRSSURL("")
      setNewRSSTitle("")
      setNewRSSActive(true)
      setNewRSSPriority(5)
      setNewRSSkeyword("")
      setNewRSSkeywords([])
      setNewRSSCategories([])
      setNewRssApprovalRequired(true)
      setRssInfo(false)
      setNewRSSPostLimit(5)
      setIsYTSearchQuery(false)
    })
  }
  
  useEffect(() => {
    props.getReposters()
    return () => {
      
    }
  }, [null])
  
  useEffect(() => {
    setRssInterval(props.redux.settings.rss_interval)
    return () => {
      
    }
  }, [props])
  
  let debounce
  const handleSettingsChange = async ({value, prop}) => {
    
    if("rss_interval" == prop) setRssInterval(value)

    clearTimeout(debounce)
    debounce = setTimeout(async ()=>{
      let settings = await fetch('/api/settings', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({prop, value})
      }).then(res=>res.json()).catch(e=>console.log({e}))
    }, 300)
  }
  return (
    <div className="container" id="admin-dashboard" style={{maxWidth: 900}}>
      <div className="row">
        <div className="col-lg-12">
          <h1>Reposters</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p>
            <TextInput placeholder="RSS Repost Interval (Minutes)" width="100%" type="number" stateless value={rssInterval} name="rss_interval" onChange={handleSettingsChange} />
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 center">
          <button className="new-reposter-button main-button" id="new-rpstr-btn" onClick={toggleAddNewReposter}>New Reposter</button>
        </div>
      </div>
      <div id="add-reposter" style={{marginBottom: 45}}>
        <div className="new-reposter-wrap row">
          <div className="col-sm-12">
            <h1>Add Reposter</h1>
          </div>
          <div className="col-sm-12">
            <TextInput
              completedColor={'#52c1f7'}
              icon="fas fa-portrait"
              color="#333"
              width={"100%"}
              name="title"
              value={title}
              placeholder="Reposter Title"
              onChange={(newText) => handleInputchange(newText, setTitle)}
            />
            <TextInput
              completedColor={'#52c1f7'}
              icon="fas fa-portrait"
              color="#333"
              width={"100%"}
              marginBottom={15}
              name="channel_id"
              value={channelID}
              placeholder="Channel ID"
              onChange={(newText) => handleInputchange(newText, setChannelID)}
            />

            <input
              type="checkbox"
              name="is_active"
              id="is_active"
              checked={reposterActive}
              onChange={(newText) => handleCheckboxChange(newText, setReposterActive)} />
              <label style={{color: '#333', marginRight: 20}} htmlFor="is_active"> &nbsp; Activated</label>

            <input
              type="checkbox"
              name="run_on_creation"
              id="run_on_creation"
              checked={runOnCreate}
              onChange={(newText) => handleCheckboxChange(newText, setRunOnCreate)} />
              <label style={{color: '#333', marginRight: 20}} htmlFor="run_on_creation"> &nbsp; Run Once On Create</label>

          </div>
          <hr style={{marginTop: 60}} />
          <div className="col-sm-12">
            <h2>Add Reposter Feeds</h2>

            <input
              type="checkbox"
              name="activated"
              id="yt-search-query"
              checked={isYTSearchQuery}
              onChange={(state) => handleCheckboxChange(state, setIsYTSearchQuery, true)} />

            <label style={{color: '#333', marginRight: 20}} htmlFor="yt-search-query"> &nbsp; Use Youtube Search RSS</label>
            
            <TextInput
              completedColor={'#52c1f7'}
              icon="fas fa-rss"
              color="#333"
              width={"100%"}
              marginBottom={15}
              name="feed_url"
              value={newRSSURL}
              placeholder={isYTSearchQuery ? "Youtube Search" : "RSS URL"}
              onChange={(newText) => handleInputchange(newText, setNewRSSURL, false, !isYTSearchQuery)}
            />
            { !isYTSearchQuery &&
              <TextInput
                completedColor={'#52c1f7'}
                icon="fas fa-rss"
                color="#333"
                width={"100%"}
                marginBottom={15}
                name="feed_title"
                value={newRSSTitle}
                placeholder={rssInfo.feed ? rssInfo.feed.title : "Feed Title"}
                onChange={(newText) => handleInputchange(newText, setNewRSSTitle)}
              />
            }
            {/* <TextInput
              completedColor={'#f00'}
              icon="fas fa-dot-circle"
              color="#333"
              width={"100%"}
              marginBottom={15}
              name="feed_priority"
              value={newRSSpriority}
              type="number"
              placeholder="Priority"
              onChange={(newText) => handleInputchange(newText, setNewRSSPriority)}
            /> */}
            <TextInput
              completedColor={'#080'}
              icon="fas fa-sort-amount-up"
              color="#333"
              width={"100%"}
              marginBottom={15}
              name="repost_limit"
              value={newRSSPostLimit}
              type="number"
              placeholder="Repost Limit (every 6 hours)"
              onChange={(newText) => handleInputchange(newText, setNewRSSPostLimit)}
            />
            <div className="keywords-wrap">
              { rssInfo.categories && <>
                <p>Suggested (recent {rssInfo.feed.title} categories): </p>
                <div>
                  { rssInfo.categories.map(category => <span className="category-tag" onClick={()=>addCategory(category)} key={category}>{category}</span>) }
                  <button className="main-button" onClick={ refreshRSSCategories }>RELOAD</button>
                </div>
                <div>
                  {newRSSCategories.map(kwd => <span className="keyword-tag" onClick={()=>deleteCategory(kwd)} key={kwd}>{kwd}</span>)}
                </div>
                </>
              }
              {newRSSKeywords.length > 0 && <>
              <div className="keywords">
                {newRSSKeywords.length > 0 && <p>Keywords</p>}
                {newRSSKeywords.map(kwd => <span className="keyword-tag" onClick={()=>deleteKeyword(kwd)} key={kwd}>{kwd}</span>)}
              </div>
              </>}
              <div style={{display: 'flex', alignItems: 'center'}}>
                <TextInput
                  completedColor={'#52c1f7'}
                  icon="fas fa-key"
                  color="#333"
                  width={"calc(100% - 150px)"}
                  marginBottom={15}
                  name="keyword"
                  value={newRSSKeyword}
                  placeholder="Keywords"
                  onChange={(newText) => handleInputchange(newText, setNewRSSkeyword)}
                />
                <button className="main-button" style={{width: 150, height: 40}} onClick={addNewRSSKeyword}>Keyword</button>
              </div>
            </div>

            <button className="main-button add-feed" style={{marginTop: 40, fontWeight: 800}} onClick={addFeed}>Add Feed</button>
            
            <input
              type="checkbox"
              name="activated"
              id="activated"
              checked={newRSSActive}
              onChange={(state) => handleCheckboxChange(state, setNewRSSActive)} />

            <label style={{color: '#333', marginRight: 20}} htmlFor="activated"> &nbsp; Activated</label>
            
            <input
              type="checkbox"
              name="request_approval"
              id="request_approval"
              checked={newRssApprovalRequired}
              onChange={(state) => handleCheckboxChange(state, setNewRssApprovalRequired)} />

            <label style={{color: '#333', marginRight: 20}} htmlFor="request_approval"> &nbsp; Approval Required</label>
            
            { feeds.length > 0 && <hr /> }
            {  
              feeds.map(feed => <>
                <div>Title: {feed.title}</div>
                <div>Active: {feed.active ? "TRUE" : "FALSE"}</div>
                {/* <div>Priority: {feed.priority}</div> */}
                {feed.keywords && <div>Keywords: {feed.keywords.map(kwd => kwd+" - ")}</div>}
              </>)
            }
          </div>
          <div className="col-sm-12 center">
            <button className="main-button create-reposter" style={{marginTop: 40, fontWeight: 800}} onClick={createReposter}>Create Reposter</button>
          </div>
          <hr style={{borderTopColor: '#ccc'}}/>
        </div>
      </div>
      {/* List all reposters */}
      <AllReposters />
            <Footer noBorder light/>
    </div>
  )
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RSS)
